import React from 'react'
import './style.css' 
import { AiOutlineInstagram } from 'react-icons/ai';
import { FiPhoneCall } from 'react-icons/fi';
import {  FaWhatsapp } from 'react-icons/fa';
import { SiDiscord } from 'react-icons/si';


function Contact() {
    return (
        <div id='contact' className="contact" >
    
           <div className="contact-div">
           <div className="contact-cont-1">
                <h1  >GET IN TOUCH</h1>
                <div>
                    Hey! We are Looking Forward To Explore Together..
                    
                </div>

                {/* <div>
                 just searchable twitter DMs alone is a massive upgrade.
                 once they add slack, linkedin, etc I'm never leaving.
                </div>

                <div>

                </div> */}
            </div>

            <div className="contact-cont-2">
                
                <div className='contact-1' style={{color:"black"}}>
                <h3 className='call-us'>CALL US</h3>
                <div className='phone_numbers'>
                <a href="tel:+91 7358629019"   className='number'><div className="underline-hover-effect" >+91 7358629019</div>  </a> 
                <a href="tel:+91 8667582984 "  className='number'><div className="underline-hover-effect">+91 8667582984</div> </a> 
                <a href="tel:+91 7010205245 "  className='number'><div className="underline-hover-effect">+91 7010205245</div> </a>   
                </div>     
                </div>

                <div className='contact-2' style={{color:"black"}}>
                 <h3 className='location' >Email </h3>
                 {/* <p  className='text-location'> stuvoxel@gmail.com</p> */}
                 <p className='text-location'><a href="mailto:stuvoxel@gmail.com" className="email underline-hover-effect">stuvoxel@gmail.com</a></p>
                </div>

                {/* <div>
                <h3 className='Business'>BUSINESS HOURS</h3>
                <p  className='text-location-1'> Mon-Fri : 9:00am -9:00pm</p>
                </div> */}
            </div>
            <div  className="contact-cont-3">
                
            <a href="https://instagram.com/stu.voxel" target="_blank"><AiOutlineInstagram size={30}   color='black'/>  </a> 
                 
            <a href='https://wa.link/hb7u1u'
            target="_blank"
            > < FaWhatsapp  size={30} color='black'/>  </a>   

            <a href="tel:+91 8667582984"
            target="_blank"
            > <FiPhoneCall  size={28} color='black'/> </a>

            <a href="https://discord.com/channels/@me/660818032922329099/895766610273505290"
            target="_blank"
            > <SiDiscord size={28} color='black'/> </a>

            
             
            </div>
 
            </div>
 
      
           
        </div>
   
    )
}

export default Contact
