import React from 'react';
import { Carousel } from 'react-bootstrap'
import Carouselimg1 from '../../assets/Carousel/caro1.jpg'
import Carouselimg2 from '../../assets/Carousel/caro2.jpg'
import Carouselimg3 from '../../assets/Carousel/caro3.jpg'
import Carouselimg4 from '../../assets/Carousel/caro4.jpg'
import Carouselimg5 from '../../assets/Carousel/caro5.jpg'
import Carouselimg6 from '../../assets/Carousel/caro6.jpg'
import Carouselimg7 from '../../assets/Carousel/caro7.jpg'
import Carouselimg8 from '../../assets/Carousel/caro8.jpg'


const CarouselContainer = () => {
    return(
    <Carousel id='home' className="carosel" variant="dark">
      <Carousel.Item
      >
        <img
        style={{width:'100%',height: '89vh',objectFit:'cover'}}
          src={Carouselimg2}
          alt="First slide"  
        />
  </Carousel.Item>
  <Carousel.Item >
    <img
    style={{width:'100%',height: '89vh',objectFit:'cover'}}
      src={Carouselimg4}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item >
    <img
    style={{width:'100%',height: '89vh',objectFit:'cover'}}
      src={Carouselimg3}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item >
    <img
    style={{width:'100%',height: '89vh',objectFit:'cover'}}
      src={Carouselimg1}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item >
    <img
    style={{width:'100%',height: '89vh',objectFit:'cover'}}
      src={Carouselimg5}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item >
    <img
    style={{width:'100%',height: '89vh',objectFit:'cover'}}
      src={Carouselimg6}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item >
    <img
    style={{width:'100%',height: '89vh',objectFit:'cover'}}
      src={Carouselimg7}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item >
    <img
    style={{width:'100%',height: '89vh',objectFit:'cover'}}
      src={Carouselimg8}
      alt="Second slide"
    />
  </Carousel.Item>
</Carousel>
    )

}

export default CarouselContainer;