import React,{useState} from 'react'
import { Carousel,Form,Row,Button,Col } from 'react-bootstrap'
import Image1 from '../../assets/testimonial/1.png'
import Image2 from '../../assets/testimonial/2.png'
import Image3 from '../../assets/testimonial/3.png'
import Image4 from '../../assets/testimonial/4.png'
import Image5 from '../../assets/testimonial/5.png'
import Image6 from '../../assets/testimonial/6.png'
import './styles.css'
import firebase from '../firebaseconfiq'
import shortId from 'shortid'
function Testimonial() {
  const [name, setName] = useState('')

  const [email, setEmail] = useState('')

  const [whatsappNumber, setWhatsappNumber] = useState('')

  const [cityCountry, setCityCountry] = useState('')

  const [profession, setProfession] = useState('')

  const [collegeWorkplace, setCollegeWorkplace] = useState('')

  const [course, setCourse] = useState('')
  


  const setTodataBase=async(e)=>{
    e.preventDefault()
    if(name===''||email===''||whatsappNumber===null||cityCountry===''||profession===''||collegeWorkplace===''||course==='' )
    {
        return alert('Please fill all the fields')
    }   

   const validateEmail = (email) => {

const emailVerify = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       return emailVerify.test(String(email).toLowerCase());

   }

   if(validateEmail(email)===false)
   {
       return alert('Email invalid')
    
   }
   const id=shortId.generate()
   await firebase.database().ref(`/data/${id}`).set({name,
    email,
    whatsappNumber,
    cityCountry,
    profession,
    collegeWorkplace, 
    course,  
    id,
    date:Date.now()
  })
  .then(()=>{
    setName('')

    setEmail('')
  
    setWhatsappNumber('')
  
    setCityCountry('')
  
    setProfession('')
  
    setCollegeWorkplace('')
  
    setCourse('')

    alert('submited')
  })

  .catch(e=>{
    alert('error',e)
  })
  }
    return (
        <div  id='Register' className="Testimonialcont">
         <div>
        <Carousel className="carosalCont"   variant="dark">
        <Carousel.Item interval={2000} >
          <img
           className="carsonalimg"
            src={Image1}
            alt="First slide"  
          />
        </Carousel.Item>
        
    <Carousel.Item interval={2000}>
      <img
         className="carsonalimg"
         src={Image2}
        alt="Second slide"
      />
      
    </Carousel.Item>
    <Carousel.Item   interval={2000}      >
      <img
       className="carsonalimg"
       src={Image3}
        alt="Second slide"
      /> 
    </Carousel.Item>
    
    <Carousel.Item interval={2000} >
      <img
       className="carsonalimg"
       src={Image4}
        alt="Second slide"
      />
    </Carousel.Item>
    <Carousel.Item interval={2000}    >
      <img
       className="carsonalimg"
       src={Image5}
        alt="Second slide"
      />
      
    </Carousel.Item>
    <Carousel.Item  interval={2000}       >
      <img
       className="carsonalimg"
       src={Image6}
        alt="Second slide"
      />
    </Carousel.Item>
  </Carousel>
  </div>

  <div className='formCont'>

  <Form onSubmit={setTodataBase}>

  <Form.Group  className="mb-1"   >
    <Form.Label>NAME</Form.Label>
    <Form.Control  type="text" placeholder="Your answer" value={name} onChange={e=>setName(e.target.value)} />  
  </Form.Group>

  <Form.Group className="mb-1" >
    <Form.Label>EMAIL ADDRESS</Form.Label>
    <Form.Control   type="email" placeholder="Your answer" value={email} onChange={e=>setEmail(e.target.value)} />
  </Form.Group>


  <Form.Group className="mb-1"   >
    <Form.Label>WHATSAPP NUMBER WITH COUNTRY CODE</Form.Label>
    <Form.Control  type="phone" placeholder="Your answer" value={whatsappNumber} onChange={e=>setWhatsappNumber(e.target.value)} />
  </Form.Group>


  <Form.Group  className="mb-1"  >
    <Form.Label>CITY / COUNTRY</Form.Label>
    <Form.Control type="phone" placeholder="Your answer" value={cityCountry} onChange={e=>setCityCountry(e.target.value)}/>
  </Form.Group>


  <Row className="mb-1">
 
  <Form.Group as={Col} controlId="formGridEmail">
    <Form.Label>PROFESSION</Form.Label>
    <Form.Select aria-label="Default select example"  value={profession} onChange={e=>setProfession(e.target.value)}>
  <option>Select</option>
  <option value="UNDERGRADUATE STUDENT">UNDERGRADUATE STUDENT</option>
  <option value="POSTGRADUATE STUDENT">POSTGRADUATE STUDENT</option>
  <option value="PROFESSIONAL">PROFESSIONAL</option>
</Form.Select>
</Form.Group>


<Form.Group as={Col} controlId="formGridEmail">
    <Form.Label> COLLEGE / WORKPLACE </Form.Label>
    <Form.Control placeholder="Your answer"  value={collegeWorkplace} onChange={e=>setCollegeWorkplace(e.target.value)}/>
  </Form.Group>
  
  </Row>
  
  <Form.Group as={Col} controlId="formGridEmail">
    <Form.Label>COURSE</Form.Label>
    <Form.Select aria-label="Default select example" value={course} onChange={e=>setCourse(e.target.value)}>
  <option>Select</option>
  <option value="PARAMETRIC ARCHITECTURE WORKSHOP">PARAMETRIC ARCHITECTURE WORKSHOP</option>
  <option value="WEBINAR">WEBINAR</option> 
</Form.Select>
</Form.Group>
{/* <Form.Group className="mb-1">
<Form.Label>MESSAGE</Form.Label>
  <FloatingLabel controlId="floatingTextarea2" label="">
    <Form.Control
      as="textarea"
      placeholder="Comment"
      style={{ height: '100px' }}
      onChange={e=>setMessage(e.target.value)}
    />
  </FloatingLabel>
  </Form.Group> */}
  <div className="subBtn">
  <Button variant="primary" type="submit" onSubmit={setTodataBase}>
    Submit
  </Button>
</div>

</Form>


  </div>
  </div>
       
    )
}

export default Testimonial
