
import React from 'react'
import Bilal from '../../assets/artist/1.Bilal.png'
import Sudhan from '../../assets/artist/2.Sudhan.png'
import Praveen from '../../assets/artist/3.Praveen.png'
import what from '../../assets/about/01.What.png'
import why from '../../assets/about/02.Why.png'
import focus from '../../assets/about/03.Focus.png'
import goal from '../../assets/about/04.Goal.png'
import whatafter from '../../assets/about/a.What.png'
import whyafter from '../../assets/about/b.Why.png'
import focusafter from '../../assets/about/c.Focus.png'
import goalafter from '../../assets/about/d.Goal.png'
import Fade from 'react-reveal/Fade';
import './styles.css'


function About() {
    return (

        <div id='about' className="demo" >
            
          <div>
            
        <div className="container about-cont-1"   >
        
         <div>

            <div className="row">
            
                <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="our-team">
                      <div className="container1">   
                         <div className="pic">
                            <img src={what} alt=""/>
                           <div className="overlay">
                             <img src={whatafter} alt="Avatar" className="image"/>
                           </div>
                         </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-6">

                    <div className="our-team">
                    <div className="container1">
                        <div className="pic">
                            <img src={why} alt=""/>
                            <div className="overlay">
     <img src={whyafter} alt="Avatar" className="image"/>
  </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="our-team">
                      <div className="container1">
                        <div className="pic">
                              <img src={focus} alt="" />
                            <div className="overlay">
                               <img src={focusafter} alt="Avatar" className="image"/>
                             </div>
                        </div>
                      </div>
                     
                    </div>

                </div>
                
                <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="our-team">
                    <div className="container1">
                        <div className="pic">
                            <img src={goal} alt=""/>
                            <div className="overlay">
     <img src={ goalafter} alt="Avatar" className="image"/>
     
  </div>

                        </div>
                      
                        </div>
                        
                        </div>
                        
                    </div>
           
                </div>
             
    <div className="AboutContent">

<Fade left>
<div className="ContextLeftText" style={{textAlign:'right' }} >
<div className="ContextLeftText"  >WE BELIEVE</div>
<div  className="ContextLeftText">COMPUTATIONAL</div>
<div  className="ContextLeftText">DESIGN IS SOMETHING</div>
<div  className="ContextLeftText">MORE THAN A</div>
<div  className="ContextLeftText">SOFTWARE LEARNING</div>
</div>
</Fade>

<div>

    <p className="ContexRightText">
    <b className="ContexRightTextbr">STUDIO VOXEL</b> is an educational and research platform for architects,
     architecture students and designers initiated by a team of three young architects to share the knowledge
     about computational design.  
<div className="ContexRightTextdiv">
     We conduct workshops on various computational concepts where we concentrate more on design based workflow rather
     than just a software tutorial class. 
    </div>
    <div className="ContexRightTextdiv">
     We make sure our participants understands the basic roots of computational design concepts and further explores,
     implementing those concepts in their design practices.</div>
    </p>
  
</div>

</div>

                </div>
              
            </div>
        
        </div>   

        <div className="container  about-cont-2"  >
            <div className="row">
            
                <div className="col-xxl-4 col-lg-12 col-md-12 col-sm-6">
                    <div className="our-team">
                        <div className="pic">
                            <img src={Bilal} alt=""/>
                        </div>
                        {/* <ul className="social">
                            <li><a href="#" className="fab fa-facebook"></a></li>
                            <li><a href="#" className="fab fa-google-plus"></a></li>
                            <li><a href="#" className="fab fa-twitter"></a></li>
                        </ul> */}
                        
                        <div className="team-content">
                            
                            <h3 className="title">MOHAMED BILAL</h3>
                        </div>
                        
                        <p  style={{textAlign:'justify'}}>
                        A Bachelor of Architecture graduate from MEASI Academy of Architecture,
                        Chennai, India. Passion towards learning and exploring new technologies and
                        tools in Architecture started his journey in Parametric and Computational Design.
                        Effectively working to create, design and communicate ideas using
                        Computational Design for the past 3 years
                        </p>
                    </div>
                </div>
                
                
                <div className="col-xxl-4 col-lg-12 col-md-12 col-sm-6">
                    <div className="our-team">
                        <div className="pic">
                            <img src={Sudhan} alt=""/>
                        </div>
                        {/* <ul class="social">
                            <li><a href="#" className="fab fa-facebook"></a></li>
                            <li><a href="#" className="fab fa-google-plus"></a></li>
                            <li><a href="#" className="fab fa-twitter"></a></li>
                        </ul> */}
                        <div className="team-content">
                             <h3 className="title">SUDHAN</h3>
                            {/* <span className="post">PRAVEEN SHIVA</span> */}
                        </div>
                        <p  style={{textAlign:'justify'}}>
                        An Architect passionate about parametric and computational design, based on Chennai.
                        Completed, Bachelors at MEASI Academy of Architecture. Startedexploring architectural geometry at very early stage with the concept of computational design.
                        Continually learning and adapting to growing trends while maintaining my own distinctive style.
                        </p>
                    </div>
                </div>
                
                <div className="col-xxl-4 col-lg-12 col-md-12 col-sm-6">
                    <div className="our-team">
                        <div className="pic">
                            <img src={Praveen} alt=""/>
                        </div>
                        {/* <ul class="social">
                            <li><a href="#" className="fab fa-facebook"></a></li>
                            <li><a href="#" className="fab fa-google-plus"></a></li>
                            <li><a href="#" className="fab fa-twitter"></a></li>
                        </ul> */}
                        <div className="team-content">
                            <h3 className="title">PRAVEEN SIVAKUMAR</h3>
                            {/* <span className="post">Web Designer</span> */}
                        </div>
                        <p  style={{textAlign:'justify'}} >
                        An Architect and a Computational designer.  Exploring mathematical derived
                        forms and algorithmic designs, since 2018.  He interned at Sanjay Puri Architects
                        and Cadence Architects, where he widened his knowledge on digital fabrication
                        and practical application of computational design.
                        </p>
                    </div>
                </div>
                
            </div>
        </div>   
    </div>
    

    )
}

export default About

