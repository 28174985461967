import React from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages';
import BackGroundVideo from './assets/Animation.mp4'


function App() {
  return (
    <div className="app">
    <video className='myVideo'
     autoPlay
     loop 
     muted
     playsInline

    //  style={{
    //   position: "absolute",
    //   width: '100%'
    //   left: '50%',
    //   top: '50%',
    //   height: '100%',
    //   object-fit: 'cover',
    //   transform: 'translate(-50%, -50%)',
    //   z-index: '-1'
    // }}
    >
  <source src={BackGroundVideo} type="video/mp4"/>
  Your browser does not support HTML5 video.
</video>


    <Router >
      <Home/>
    </Router>
    </div>
  );
}

export default App;
