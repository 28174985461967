import React from 'react'
import image from '../../assets/workflow/Workflow.png'
import Fade from 'react-reveal/Fade';
import './styles.css'
const Workflow=()=> {
    return (

        <div id='workflow' className="workFlow">
            
            {/* <div className="workflow-header">WORKFLOW</div> */}
            <div className='container'>
            <img src={image} alt='workflow' className="workflow-img" />
            </div>

            
            <div className="container workflow-content-cont">
                <div  className="workflow-content-div">
                    
                <Fade bottom>   <h5 className="workflow-content-header">Prototyping</h5> </Fade>
                    <div className="workflow-content-text"> 
                    The initial concept of the geometry is sketched and visualized using pen and paper. With help of the 3D design tools and the schematic ideas,the geometry has been designed in digital medium.
                    </div>
               </div>
                <div className="workflow-content-div">
                <Fade bottom>   <h5 className="workflow-content-header">Design Development</h5>  </Fade>
                    <div className="workflow-content-text">
                    The geometry is analysed using design constraints like structural, environmental (solar radiation, wind analysis), panelling, etc.Various iterations from the analysis are optimised to get the resultant geometry.
                   </div>
                </div>
                <div className="workflow-content-div-alter">
                <Fade bottom>    <h5 className="workflow-content-header">Extraction and Execution</h5>  </Fade>
                    <div className="workflow-content-text">The challenging part in designing complex geometry starts when we visualize the geometry in the real world.With help of digital fabrication knowledge the initial idea of our complex geometry can be fabricated.</div>
                </div>   
            </div>
            
            
        </div>
    )
}

export default Workflow
