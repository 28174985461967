import firebase from 'firebase'


const firebaseConfig = {
    apiKey: "AIzaSyCwaA0HXYK9Jl2nMcR91aOr0OoRPwaVmRo",
    authDomain: "studio-voxel.firebaseapp.com",
    projectId: "studio-voxel",
    storageBucket: "studio-voxel.appspot.com",
    messagingSenderId: "160295771322",
    appId: "1:160295771322:web:8c027f7a21da3613077683",
    measurementId: "G-74WS4YBMEY"
  };
  
  const firebaseApp=firebase.initializeApp(firebaseConfig)


  export default firebaseApp;