import React from 'react'
import logo1 from '../../assets/hero/herologo.png'
import logo2 from '../../assets/hero/herologo2.png'
import logo3 from '../../assets/hero/herologo3.png'
import image1 from '../../assets/home/1.jpg'
import image1After from '../../assets/home/1.1.jpg'
import image2 from '../../assets/home/2.jpg'
import image2After from '../../assets/home/2.1.jpg'
import image3 from '../../assets/home/3.jpg'
import image3After from '../../assets/home/3.1.jpg'
import image4 from '../../assets/home/4.jpg'
import image4After from '../../assets/home/4.1.jpg'
import image5 from '../../assets/home/5.jpg'
import image5After from '../../assets/home/5.1.jpg'
import image6 from '../../assets/home/6.jpg'
import image6After from '../../assets/home/6.1.jpg'
import './styles.css'

function HeroSection() {
    return (
        <div className="heroSection">
            <div className="heroSectioncont" >
            <h1 className="heroSection-cont-header">PARAMETRIC ARCHITECTURE & COMPUTATIONAL DESIGN WORKSHOP</h1>
            <div className="heroSection-logo-cont">
             <img src={logo2} alt='rino' className="heroSection-logo"/>
             <img src={logo3}  alt='rino' className="heroSection-logo"/>
             <img src={logo1}  alt='rino' className="heroSection-logo"/>
            </div>
            <div className="heroSection-cont-text">
            <div>6 DAYS (24 HOURS) INTERACTIVE ONLINE WORKSHOP </div>
            <div>TIME: 2:00PM TO 6:30PM IST (30 MINUTES BREAK)</div>
            <div>FEE:5000 INR/80USD (FOR INTERNATIONAL PARTICIPANTS)</div>
            </div>

            <div className="heroimgcont">
      
            <div className="heroimgcontdiv">
              <img src={image1} alt='rino' className="HeroSection-img" />
              <div className="middle">
                <img src={image1After} alt='rino'  className="HeroSection-img" />
              </div>
            </div>
              
              <div className="heroimgcontdiv">
              <img src={image2}  alt='rino' className="HeroSection-img" />
              <div className="middle">
              <img src={image2After}  alt='rino' className="HeroSection-img" />
             </div>
             
             </div>
              <div className="heroimgcontdiv">
              <img src={image3} alt='rino' className="HeroSection-img" />
              <div className="middle">
              <img src={image3After} alt='rino' className="HeroSection-img" />
             </div>
          </div>
              
            </div>

      <div className="heroimgcont2">

      <div className="heroimgcontdiv">
        <img src={image4} alt='rino'  className="HeroSection-img" />
        <div className="middle">
          <img src={image4After} alt='rino'  className="HeroSection-img" />
       </div>
        </div>

        <div className="heroimgcontdiv">
        <img src={image5} alt='rino' className="HeroSection-img" />
        <div className="middle">
        <img src={image5After} alt='rino' className="HeroSection-img" />
       </div> 
       </div>

        <div className="heroimgcontdiv">
        <img src={image6} alt='rino' className="HeroSection-img" />
        <div className="middle">
        <img src={image6After} alt='rino' className="HeroSection-img" />
       </div>
       </div>
        
      </div>

        </div>
     
        </div>
    )
}

export default HeroSection

