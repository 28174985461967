import React from 'react'
import img1 from '../../assets/exploration/01.jpg'
import img2 from '../../assets/exploration/02.jpg'
import img3 from '../../assets/exploration/03.jpg'
import img4 from '../../assets/exploration/04.jpg'
import img5 from '../../assets/exploration/05.jpg'
import img6 from '../../assets/exploration/06.jpg'
import img7 from '../../assets/exploration/07.jpg'
import img8 from '../../assets/exploration/08.jpg'

import './style.css'
const Exploration=()=> {
    return (
    <div id='exploration' className="exploration-cont">
            <div className='container'>

        <div className="Exploration-img-cont">
      
        <div className="container-expo">
                <img src={img1} alt='pic' className="Exploration-img" />
                <div className="middle-expo">
                  <div className="text-expo">Ribbon Chapel</div>
     
            </div>
                </div>

      
        <div className="container-expo">
                <img src={img2} alt='pic' className="Exploration-img" />
                <div className="middle-expo">
                  <div className="text-expo">Voxelization</div>
                </div>

                </div>

    
        <div className="container-expo">
                <img src={img3} alt='pic1' className="Exploration-img" />
                <div className="middle-expo">
                  <div className="text-expo">Bosjes Chapel</div>
  
            </div>
                </div>

          
        <div className="container-expo">
                <img src={img4} alt='pic2'  className="Exploration-img" />
                <div className="middle-expo">
                  <div className="text-expo">Arc’vilion</div>
      
            </div>
                </div>
      </div>

      
      <div className="Exploration-img-cont1">
        <div className="container-expo">
                <img src={img5} alt='pic' className="Exploration-img" />
                <div className="middle-expo">
                  <div className="text-expo">Iso pavilion</div>
            </div>
                </div>

        <div className="container-expo">
                <img src={img6} alt='pic' className="Exploration-img" />
                <div className="middle-expo">
                  <div className="text-expo">Cyber HUT</div>
            </div>
                </div>
  
        <div className="container-expo">
                <img src={img7} alt='pic' className="Exploration-img" />
                <div className="middle-expo">
                  <div className="text-expo">Al-Bahar Towers</div>
            </div>
                </div>

  
        <div className="container-expo">
                <img src={img8} alt='pic' className="Exploration-img" />
                <div className="middle-expo">
                  <div className="text-expo">Aggregation</div>
    
            </div>
                </div>
                </div>
                </div>
    </div>
    )
}

export default Exploration
